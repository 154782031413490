/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
@keyframes fade-into-foreground {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes fade-into-background {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(.75);
		opacity: 0;
	}
}

@keyframes pop-into-foreground {
	from {
		transform: scale(.5);
		opacity: 0;
	} to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-into-foreground-with-bounce {
	0% {
		transform: scale(.5);
		opacity: 0;
	} 72% {
		transform: scale(1.07);
		opacity: .72;
	} 100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
	} 100% {
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
	}
}

@keyframes slide-from-top {
	from {
		transform: translateY(-100%);
	} to {
		transform: translateY(0);
	}
}
*/
.homepage-jobs {
  background-color: #e0e9f3;
  padding: 85px 0 65px;
}

.galleries__list {
  display: grid;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
}
@media screen and (min-width: 450px) {
  .galleries__list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 800px) {
  .galleries__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1600px) {
  .galleries__list {
    grid-template-columns: repeat(6, 1fr);
  }
}
.galleries__item {
  position: relative;
  padding-bottom: 65.625%;
  overflow: hidden;
}
.galleries__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.galleries__name {
  position: absolute;
  bottom: 110%;
  left: 15px;
  right: 15px;
  transition: bottom 0.2s ease-out;
}
.galleries__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: rgba(0, 8, 57, 0) url(/assets/images/wave-white.svg) no-repeat 50% 150%/59px auto;
  transition: background-color 0.15s ease-out, background-position 0.2s ease-out;
  text-align: center;
}
.galleries__link, .galleries__link:link, .galleries__link:visited {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.0625rem;
  text-decoration: none;
}
.galleries__link:hover, .galleries__link:focus, .galleries__link:active {
  background-color: rgba(0, 8, 57, 0.8);
  background-position: 50% 65%;
}
.galleries__link:hover .galleries__name, .galleries__link:focus .galleries__name, .galleries__link:active .galleries__name {
  bottom: 50%;
}