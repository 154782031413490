@use "sass:math";

/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars",
	"abstracts/animations";

//@import "components/blender";

.homepage-jobs {
	background-color: #e0e9f3;
	padding: 85px 0 65px;
}

.galleries {
	&__list {
		display: grid;
		list-style-type: none;
		margin: 0;
		padding: 0;
		position: relative;

		@include min(450px) {
			grid-template-columns: 1fr 1fr;
		}

		@include min(800px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@include min(1600px) {
			grid-template-columns: repeat(6, 1fr);
		}
	}

	&__item {
		position: relative;
		padding-bottom: ratio(320, 210);
		overflow: hidden;
	}

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	&__name {
		position: absolute;
		bottom: 110%;
		left: 15px;
		right: 15px;
		transition: bottom .2s ease-out;
	}

	&__link {
		@include fill;
		z-index: 5;
		background: rgba(#000839, 0) url(/assets/images/wave-white.svg) no-repeat 50% 150% / 59px auto;
		transition: background-color .15s ease-out, background-position .2s ease-out;
		text-align: center;

		@include link(&) {
			color: white;
			text-transform: uppercase;
			font-weight: bold;
			font-size: rem(17px);
			text-decoration: none;
		}

		@include link-over(&) {
			background-color: rgba(#000839, .8);
			background-position: 50% 65%;

			.galleries__name {
				bottom: 50%;
			}
		}
	}
}
